<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(send_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'
import ComptIdxColumn from '@/components/ComptIdxColumn.vue'
import TagbyAdvColumn from '@/components/TagbyAdvColumn.vue'
import TagbyInfColumn from '@/components/TagbyInfColumn.vue'

export default defineComponent({
  components: {
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
    ComptIdxColumn,
    TagbyAdvColumn,
    TagbyInfColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    } = useItems()
    fetchItems()

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    }
  },
})
</script>
