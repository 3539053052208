import {
  computed,
} from '@vue/composition-api'
import data from './data'
import api from './api'
import useToast from '@/utils/toast'
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from '@/utils/syncUrl'

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: data.currentPage.value,
    size: data.perPage.value,
    total: data.totalRows.value,
    ...data.filterParams.value,
  }))

  const categoryOptions = [
    {
      label: 'MESSAGE',
      paramName: 'message_list',
    },
    {
      label: 'MEMBER_IDX',
      paramName: 'member_idx_list',
    },
    {
      label: 'MEMBER_TYPE',
      paramName: 'member_type_list',
      format: 'checkbox',
      options: [
        { text: 'ADV', value: 'ADV' },
        { text: 'INF', value: 'INF' },
      ],
    },
    {
      label: 'CREATED_AT',
      paramName: 'created_at_list',
      format: 'date',
    },
    {
      label: 'IS_READ',
      paramName: 'is_read_list',
      format: 'checkbox',
      options: [
        { text: 'true', value: true },
        { text: 'false', value: false },
      ],
    },
  ]

  const fetchItems = () => {
    data.isBusy.value = true
    api.fetchItems({
      ...searchParams.value,
    }).then(response => {
      const resData = response.data
      data.items.value = resData.data
      data.totalRows.value = resData.page_info.total_count
      data.filterParams.value.total = resData.page_info.total_count
      replaceRouterQuery(searchParams.value)
    }).catch(() => {
      makeToast('danger', '목록을 쿼리하는데 실패 했습니다')
    }).finally(() => {
      data.isBusy.value = false
    })
  }

  const search = params => {
    data.currentPage.value = 1
    data.filterParams.value = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchItems()
  }

  const tableColumns = [
    { key: 'idx', label: 'idx' },
    { key: 'member_type', label: 'adv' },
    { key: 'member_idx', label: 'inf' },
    { key: 'sending_state', label: 'sending_state' },
    { key: 'is_read', label: 'is_read' },
    { key: 'send_at', label: 'send_at' },
    { key: 'created_at', label: 'created_at' },
    { key: 'message', label: 'message' },
  ]

  const changePage = params => {
    data.currentPage.value = params.page
    fetchItems()
  }

  return {
    categoryOptions,
    ...data,
    fetchItems,
    tableColumns,
    search,
    changePage,
  }
}
